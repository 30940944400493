import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Registrations from './admin_panel/Registrations/Registrations';
import { useEffect, useState } from "react";
import SignIn from './SignIn/SignIn';
import Events from './admin_panel/Events/Events';
import Api from './API/Api';
function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    };

    fetch(`Api/user/login_check`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // setUser(data.user);
        setLoading(false);
      })
      .catch((err) => { console.log(err); localStorage.removeItem('token'); })
      .finally(() => setLoading(false));
  }, []);
  return (

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route path='/Registrations' element={<Registrations />} />
        <Route path='/Events' element={<Events />} />
      </Routes>


    </BrowserRouter>
  );
}

export default App;
