import React, { useState, useRef, useEffect } from 'react';
import classes from "./Events.module.css"
import { Button, Form, Input, Modal, DatePicker, message, Space, Table , Pagination } from 'antd';
import Api from '../../API/Api';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, SearchOutlined, LoadingOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from "moment"

// ADD EVENT FORM 
const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={open}
      title="Add a new Event"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please input the title',
            },
          ]}
        >
          <Input style={{borderRadius: '6px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="description" label="Description Link"
          rules={[
            {
              required: true,
              message: 'Please input the description',
            },
          ]}>
          <Input type="textarea" style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="imageLink" label="Image Link"
          rules={[
            {
              required: true,
              message: 'Please input the Image Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="registerLink" label="Registration Link"
          rules={[
            {
              required: true,
              message: 'Please input the Registration Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="fbLink" label="Facebook Link"
          rules={[
            {
              required: true,
              message: 'Please input the Facebook Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="instaLink" label="Instagram Link"
          rules={[
            {
              required: true,
              message: 'Please input the Instagram Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="twitterLink" label="Twitter Link"
          rules={[
            {
              required: true,
              message: 'Please input the Twitter Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="ldLink" label="LinkedIn Link"
          rules={[
            {
              required: true,
              message: 'Please input the LinkedIn Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="date" label="Date"
          rules={[
            {
              required: true,
              message: 'Please input the LinkedIn Link',
            },
          ]}>
          <DatePicker style={{borderRadius: '5px', boxShadow:"none", }} placeholder="" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

// EDIT EVENT FORM 
const CollectionCreateForm2 = ({ open, val, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={open}
      title="Edit Event"
      okText="Edit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={val}
      >
        <Form.Item
          name="_id"
          label="Database ID (not for use)"
          rules={[
            {
              required: true,
              message: 'Please input the _id',
            },
          ]}
        >
          <Input style={{borderRadius: '6px', boxShadow:"none"}} disabled={true}/>
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please input the title',
            },
          ]}
        >
          <Input style={{borderRadius: '6px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="description" label="Description Link"
          rules={[
            {
              required: true,
              message: 'Please input the description',
            },
          ]}>
          <Input type="textarea" style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="imageLink" label="Image Link"
          rules={[
            {
              required: true,
              message: 'Please input the Image Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="registerLink" label="Registration Link"
          rules={[
            {
              required: true,
              message: 'Please input the Registration Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="fbLink" label="Facebook Link"
          rules={[
            {
              required: true,
              message: 'Please input the Facebook Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="instaLink" label="Instagram Link"
          rules={[
            {
              required: true,
              message: 'Please input the Instagram Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="twitterLink" label="Twitter Link"
          rules={[
            {
              required: true,
              message: 'Please input the Twitter Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="ldLink" label="LinkedIn Link"
          rules={[
            {
              required: true,
              message: 'Please input the LinkedIn Link',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
        <Form.Item name="date" label="Date"
          rules={[
            {
              required: true,
              message: 'Please input the Date',
            },
          ]}>
          <Input style={{borderRadius: '5px', boxShadow:"none"}}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};


function Events() {
  // FORMS CODE 
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)

  const info = () => {
    message.info('Event added');
  };

  const info2 = () => {
    message.info('Event edited');
  };

  const info3 = () => {
    message.info('Event deleted');
  };

  const error = () => {
    message.error('Failed to add event');
  };

  const error2 = () => {
    message.error('Failed to Fetch event');
  };

  const error3 = () => {
    message.error('Failed to Delete event');
  };

  const error4 = () => {
    message.error('Failed to Edit event');
  };

  const onCreate = async (values) => {
    try{
      values.date = new Date(values?.date?._d).toLocaleDateString('en-GB')
      console.log('Received values of form: ', values);
      const requestOptions = {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + localStorage.getItem('token') 
        },
      };
      const res = await Api.post("/event/addEvent", values, requestOptions);
      const data = await res.data;
      info();
      await fetchData();
      setOpen(false);
    }catch(e){
      console.log(e);
      error();
      setOpen(false);
    }
  };

  const handlr = ()=>{
    navigate('/Registrations')
  }

  const lout = ()=>{
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate('/')
  }

  // TABLE CODE 
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      // width: '20%',
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Description Link',
      dataIndex: 'description',
      key: 'description',
      // width: '20%',
      ...getColumnSearchProps('description'),
      render: (string) => string.length > 10 ? 
      string.substring(0, 10) + "..." : 
      string
    },
    {
      title: 'Image',
      dataIndex: 'imageLink',
      key: 'imageLink',
      render: imageLink => <img src={imageLink} width="60rem"/>
    },
    {
      title: 'Register Link',
      dataIndex: 'registerLink',
      key: 'registerLink',
      // width: '20%',
      ...getColumnSearchProps('registerLink'),
      render: (string) => string.length > 10 ? 
      string.substring(0, 10) + "..." : 
      string,
    },
    {
      title: 'Facebook Link',
      dataIndex: 'fbLink',
      key: 'fbLink',
      ...getColumnSearchProps('fbLink'),
      render: (string) => string.length > 10 ? 
      string.substring(0, 10) + "..." : 
      string
    },
    {
      title: 'Insta Link',
      dataIndex: 'instaLink',
      key: 'instaLink',
      ...getColumnSearchProps('instaLink'),
      render: (string) => string.length > 10 ? 
      string.substring(0, 10) + "..." : 
      string
    },
    {
      title: 'Twitter Link',
      dataIndex: 'twitterLink',
      key: 'twitterLink',
      ...getColumnSearchProps('twitterLink'),
      render: (string) => string.length > 10 ? 
      string.substring(0, 10) + "..." : 
      string
    },
    {
      title: 'LinkedIn Link',
      dataIndex: 'ldLink',
      key: 'ldLink',
      // width: '20%',
      ...getColumnSearchProps('ldLink'),
      render: (string) => string.length > 10 ? 
      string.substring(0, 10) + "..." : 
      string
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      // width: '20%',
      sorter: (a, b) => new Date(moment(a.date, "MMMM Do YYYY, h:mm:ss a").format("LLL")) - new Date(moment(b.date, "MMMM Do YYYY, h:mm:ss a").format("LLL")),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('date'),
    },
    {
      title: 'Delete',
      dataIndex: '_id',
      key: '_id',
      render: _id => <DeleteOutlined onClick={()=>dlEvent(_id)}/>
    },
    {
      title: 'Edit',
      dataIndex: '_id',
      key: '_id',
      render: _id => <EditOutlined onClick={()=>edEvent(_id)}/>
    }
  ];

  // Ftech data 
  const fetchData = async()=>{
    try{
      setLoader(true);
      const requestOptions = {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + localStorage.getItem('token') 
        },
      };
      const res = await Api.get("/event/getEvents", requestOptions);
      const data = await res.data;
      console.log(data);
      setData(data?.events)
      setLoader(false);
    }catch(e){
      console.log(e);
      error2();
    }
  }

  const dlEvent= async (_id)=>{
    try{
      setLoader(true);
      const requestOptions = {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + localStorage.getItem('token') 
        },
      };
      const res = await Api.post("/event/deleteEvent",{_id:_id} ,requestOptions);
      const data = await res.data;
      // console.log(data);
      info3()
      await fetchData()
      setLoader(false);
    }catch(e){
      console.log(e);
      error3();
    }
  }

  const edEvent = (id)=>{
    console.log(id);
    for (let ind = 0; ind < data.length; ind++) {
      const element = data[ind];
      if(element._id == id){
        setMyForm(
          <CollectionCreateForm2
            open={true}
            val={element}
            onCreate={onCreate2}
            onCancel={() => {
                setMyForm();
              }}
            />)
        break;
      }
    }
  }

  const onCreate2 = async(values)=>{
      try{
        const requestOptions = {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + localStorage.getItem('token') 
          },
        };
        const res = await Api.post("/event/editEvent", values, requestOptions);
        const data = await res.data;
        info2();
        await fetchData();
        setMyForm()
      }catch(e){
        console.log(e);
        error4();
        setMyForm()
      }
  }

  const [myForm, setMyForm] = useState()

  useEffect(() => {
    fetchData();
  }, [])
  

  return (
    <div className={classes.Registrations}>
    <div className={classes.head}>
        <div>
        Events
        </div>
        <Button type="primary" size='large' onClick={()=>handlr()}>Registrations</Button>
        <Button type="primary" size='large' onClick={()=>lout()}>Log out</Button>
    </div>
    <div style={{marginTop:"1rem", marginBottom:"1rem"}}>
      <Button
        type="primary"
        size='large'
        onClick={() => {
          setOpen(true);
        }}
      >
        New Event
      </Button>
      <CollectionCreateForm
        open={open}
        onCreate={onCreate}
        onCancel={() => {
          setOpen(false);
        }}
      />
    {myForm}
    </div>
    <div className={classes.list}>
    {
      loader == true?
      <div className={classes.loader}>
      <LoadingOutlined style={{ fontSize: '48px', color: '#08c', padding:"1rem" }}/>
      </div>:
      <>
      <Table columns={columns} dataSource={data} pagination= { {pageSizeOptions: ['5', '10', '15', '20'], showSizeChanger: true}} />
      </>
    }
    </div>
    </div>
  );
}

export default Events